<template>
    <div class="spin" v-if="start" :style="styles">
        <div class="ring">
            <div class="lds-dual-ring">
                <div></div>
                <div></div>
            </div>
        </div>

        <div class="spin-message">
            <h3>{{ message || 'Carregando...' }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpinnerComponent',
    props: [
        'start',
        'message',
        'styles'
    ]
}
</script>

<style scoped>
.spin {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}   
.ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    height: 10%;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.spin-message {
    color: #ffffff;
    margin-bottom: 12%;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1440px) {
    .spin {
        min-height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .ring {
        top: 40%;
        left: 35%;
    }
}
</style>