<template>
  <SpinnerComponent :key="spin.value" :start="spin.value" :message="spin.message" :styles="spin.styles" />
  <router-view />
</template>

<script>
import SpinnerComponent from './components/common/SpinnerComponent.vue'

export default {
  name: 'App',
  components: {
    SpinnerComponent
  },
  data() {
    return {
      spin: { value: false, message: 'Carregando...', styles: '' }
    }
  },
  provide () {
    return { makeSpin: this.spin }
  }
}
</script>

<style>
@font-face {
  font-family: 'Fivo Sans Modern Bold';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Bold_i6ritq.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Regular';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Regular_dxkwd3.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern ExtBlack';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-ExtBlack_bo75ny.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Medium';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Medium_yujzzk.otf);
}

@font-face {
  font-family: 'Fivo Sans Modern Heavy';
  src: url(https://res.cloudinary.com/flashvolve/raw/upload/v1678903211/fonts/FivoSansModern-Heavy_gchhqo.otf);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: 'Fivo Sans Modern Regular', sans-serif;  

}
</style>
